<template>
  <div class="Step__form pb-0 position-relative">
    <ui-form
      class="my-3"
      data-test-id="user-signup_form_step-3"
      @submitform="handlerFormSubmit"
    >
      <ui-validate @status="({ detail }) => formStatus = detail">
        <h1
          class="mb-3"
          data-test-id="signup-user-title"
        >
          {{ $t('refactor.user_signup.step_3.title') }}
        </h1>
        <ui-alert
          :color="webAppColors.informative"
          :icon="ICONS.infoFull"
          class="d-block mb-5"
          data-test-id="signup-user-subtitle"
        >
          {{ subtitle }}
        </ui-alert>
        <ui-select
          v-validate.select="{
            isRequired: {
              message: $t('refactor.company_signup.errors.mandatory'),
            },
          }"
          data-test-id="user-signup_form_select-title"
          class="w-100 d-block mb-4"
          name="title"
          :label="$t('refactor.user_signup.step_3.fields.title')"
          :options.prop="mappedTitles"
          :value="data.title"
          :placeholder="$t('refactor.user_signup.step_3.fields.title_placeholder')"
          @selectoption="({ detail }) => setTitle(detail)"
        />
        <ui-text-input
          v-validate.blurinput="{
            isRequired: {
              message: $t('refactor.company_signup.errors.mandatory'),
              whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
            },
          }"
          :label="$t('refactor.user_signup.step_3.fields.first_name')"
          :value="data.firstName"
          data-test-id="user-signup_form_input-name"
          class="w-100 d-block mb-4"
          name="firstName"
          @blurinput="onBlurTrackNameCreated"
          @changevalue="({ detail }) => setFirstName(detail)"
          @focusinput="resetInputValidation"
        />
        <ui-text-input
          v-validate.blurinput="{
            isRequired: {
              message: $t('refactor.company_signup.errors.mandatory'),
              whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
            },
          }"
          :class="[
            isTaxNumberVisible || isIdNumberVisible ? 'mb-5' : 'mb-3',
            'w-100 d-block'
          ]"
          :label="$t('refactor.user_signup.step_3.fields.last_name')"
          :value="data.lastName"
          data-test-id="user-signup_form_input-lastname"
          name="lastName"
          @blurinput="onBlurTrackNameCreated"
          @changevalue="({ detail }) => setLastName(detail)"
          @focusinput="resetInputValidation"
        />
        <InputTax
          v-if="isTaxNumberVisible"
          :class="[
            isIdNumberVisible ? 'mb-5' : 'mb-3',
            'w-100 d-block'
          ]"
          :country="countryCode"
          :invalid-tax-numbers="invalidTaxNumbers"
          :label="$t('refactor.company_signup.fields.tax')"
          :model-value="data.taxNumber"
          :placeholder="$t('refactor.company_signup.fields.tax')"
          data-test-id="company_vat_number-input_text"
          name="taxNumber"
          @change="setTaxNumber"
          @isValid="isValid => isTaxNumberValid = isValid"
        />
        <ui-text-input
          v-if="isIdNumberVisible"
          v-validate.blurinput="{
            isRequired: {
              message: $t('refactor.company_signup.errors.mandatory'),
              whiteSpaceMessage: $t('refactor.company_signup.errors.mandatory'),
            },
          }"
          :class="[
            b2b.isCorporateUser ? 'mb-5' : 'mb-3',
            'w-100 d-block'
          ]"
          :label="$t('refactor.user_signup.step_3.fields.id_number')"
          :placeholder="$t('refactor.user_signup.step_3.fields.id_number_placeholder')"
          :value="data.idNumber"
          name="idNumber"
          @changevalue="({ detail }) => setIdNumber(detail)"
          @focusinput="resetInputValidation"
        />
        <ExtraInformationComponent v-if="!b2b.isCorporateUser" />
        <PromoCodeComponent v-if="!isEmployee" />
        <LegalDocumentsComponent
          v-if="info.legalDocuments"
          :accepted="acceptedLegalDocuments"
          :documents="info.legalDocuments"
          @accept="({ value }) => trackAndAcceptLegalDocuments(value)"
          @reject="({ value }) => rejectLegalDocument(value)"
        />
        <SubscriptionGroupsComponent
          v-if="!ownsEmployeeData"
          :marketing-news="data.receiveNews"
          :subscriptions="subscriptionGroups"
          :use-subscriptions="areSubscriptionsEnabled"
          @update:marketing-news="setReceiveNews"
          @update:subscriptions="setSubscriptionGroups"
        />
        <ui-button
          v-bind="fetchButtonSpecs()"
          data-test-id="user-signup_form_button-submit"
          class="d-block mt-5 mb-6"
          :size="SIZES.large"
          :disabled="isDisabled"
          :loading="loading || isPromoCodeCheckLoading"
          :type="BUTTON_TYPES.submit"
        >
          {{ $t('refactor.user_signup.step_4.create_account') }}
        </ui-button>
      </ui-validate>
    </ui-form>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import every from 'lodash/every';
import get from 'lodash/get';
import includes from 'lodash/includes';
import lowerCase from 'lodash/lowerCase';
import isString from 'lodash/isString';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { useTrackingGTM } from '@/composable/GTM/gtm';
import { BUTTON_TYPES, NOTIFICATION_TYPE, Validate } from '@emobg/vue-base';
import { DATE_FORMAT, isFalsyString } from '@emobg/web-utils';
import config from '@/config';
import SegmentMixin from '@/mixins/Segment';
import SESSION_STORAGE from '@/constants/session-storage';
import { SEGMENT_EVENTS } from '@/vue/constants';
import InputTax from '@/components/InputTax/InputTax';
import LegalDocumentsComponent from '@/domains/Document/components/LegalDocuments/LegalDocumentsComponents';
import { useAcceptLegalDocuments } from '@/composable/User/legalDocuments';
import { useNotifications } from '@/composable/App/Notifications/useNotifications';
import { UTM_PARAMETERS } from '@/constants/utms';
import { shouldSendAttributedEvent } from '@/utils/utms';
import { NAMESPACE as PromotionsModuleNameSpace } from '@/stores/User/Promotions/PromotionsModule';
import { useTheme } from '@/composable/Theme/useTheme';

import {
  businessData as b2b,
  company,
  createEmployee,
  createEmployeeFromInvitation,
  createUser,
  signUpData as data,
  stepsDone as done,
  genericInfo as info,
  isEmployee,
  isInviteValid,
  isPhoneNumberValid,
  ownsEmployeeData,
  promoCode,
  setFirstName,
  setForceErrorOnWrongPromoCode,
  setIdNumber,
  setLastName,
  setPromoCode,
  setReceiveNews,
  setTaxNumber,
  setTitle,
} from '@/stores/SignUp/User/UserSignUpMapper';

import {
  isLoading as areSubscriptionsLoading,
  fetchSubscriptions,
  isSaving,
  registerModule,
  setSubscriptionGroups,
  subscriptionGroups,
} from '@/stores/NotificationCenter/NotificationCenterMapper';

import { countries, personTitles } from '@/stores/SignUp/Generic/GenericSignUpMapper';
import { GTM_CLICK_ACTIONS_AREAS, GTM_EVENTS, GTM_SIGN_UP_EVENT_VALUES } from '@/constants/gtm';
import { errorNotification } from '@/handlers/errorHandler.const';

import { webAppColors } from '@/constants/themes';
import { isItalianUserOrCompany } from '../../helpers';
import SubscriptionGroupsComponent from '../../components/SubscriptionGroups/SubscriptionGroupsComponent';
import ExtraInformationComponent from '../components/ExtraInformationComponent';
import PromoCodeComponent from '../components/PromoCodeComponent';
import { DEFAULT_SIGNUP_RESTRICTIONS } from '../constants/restrictions.const';
import { STEPS } from '../constants/steps.const';
import { resetInputValidation } from '../../../utils/validation.util';
import { COUNTRY_FIELDS } from './constants/countryFields.const';

export default {
  name: 'Step3View',
  components: {
    ExtraInformationComponent,
    InputTax,
    LegalDocumentsComponent,
    PromoCodeComponent,
    SubscriptionGroupsComponent,
  },
  directives: {
    Validate,
  },
  mixins: [
    SegmentMixin,
  ],
  setup() {
    const { trackInteractionEvent } = useTrackingGTM();
    const {
      acceptedLegalDocuments,
      acceptLegalDocument,
      rejectLegalDocument,
      areAccepted,
    } = useAcceptLegalDocuments();
    const { notify, removeAllNotifications } = useNotifications();
    const { fetchButtonSpecs } = useTheme();
    return {
      webAppColors,
      acceptedLegalDocuments,
      acceptLegalDocument,
      rejectLegalDocument,
      areAccepted,
      trackInteractionEvent,
      notify,
      removeAllNotifications,
      fetchButtonSpecs,
    };
  },
  data() {
    return {
      formStatus: undefined,
      loading: false,
      invalidTaxNumbers: [],
      isSubmitButtonDisabled: false,
      isTaxNumberValid: false,
    };
  },
  computed: {
    areSubscriptionsLoading,
    isSaving,
    subscriptionGroups,
    data,
    b2b,
    done,
    info,
    company,
    ownsEmployeeData,
    promoCode,
    isInviteValid,
    isEmployee,
    isPhoneNumberValid,
    personTitles,
    countries,
    ...mapState(PromotionsModuleNameSpace, {
      promoCodeState: state => state.code,
      promoCodeStatus: state => state.code.data.validPromotion,
      isPromoCodeCheckLoading: state => state.code.STATUS.LOADING,
      isPromoCodeCheckLoaded: state => state.code.STATUS.LOADED,
    }),
    countryCode() {
      return lowerCase(get(this, 'data.country.code', ''));
    },
    isTaxNumberVisible() {
      const configIdNumber = get(COUNTRY_FIELDS, 'user.taxNumber', []);
      return includes(configIdNumber, this.countryCode);
    },
    isIdNumberVisible() {
      const configIdNumber = get(COUNTRY_FIELDS, 'user.idNumber', []);
      return includes(configIdNumber, this.countryCode);
    },
    subtitle() {
      if (this.b2b.isCorporateUser) {
        return this.$t('refactor.user_signup.step_3.subtitle');
      }
      const companyCountry = get(this, 'data.country.code');
      const country = find(this.countries, { code: companyCountry });

      const minDriverAge = get(this.b2b, 'csOperator.minDriverAge')
        || get(this, 'data.country.minDriverAge')
        || get(country, 'minDriverAge', DEFAULT_SIGNUP_RESTRICTIONS.minAge);

      const minLicenseYears = get(this.b2b, 'csOperator.minLicenseYears')
        || get(this, 'data.country.minLicenseYears')
        || get(country, 'minLicenseYears')
        || get(this.b2b, 'invitation.min_license_years')
        || get(this.b2b, 'company.min_license_years', DEFAULT_SIGNUP_RESTRICTIONS.minLicenseYears);

      return isFalsyString(minLicenseYears)
        ? this.$t('refactor.user_signup.step_3.subtitle_note_no_license_years', {
          operator: config.data.appName,
          minDriverAge,
        })
        : this.$t('refactor.user_signup.step_3.subtitle_note', {
          operator: config.data.appName,
          minLicenseYears,
          minDriverAge,
        });
    },
    isItalianUser() {
      return isItalianUserOrCompany(get(this, 'data.country.code'), get(this, 'data.address.countryCode'));
    },
    isFormValid() {
      return every(
        [
          this.data.firstName,
          this.data.lastName,
          !this.isTaxNumberVisible || (this.data.taxNumber && this.isTaxNumberValid),
          !this.isIdNumberVisible || this.data.idNumber,
          this.b2b.isCorporateUser || !this.isItalianUser || this.data.italianFiscalCode,
          this.b2b.isCorporateUser || (this.data.address && this.data.address.address),
          this.b2b.isCorporateUser || this.data.birthDate,
          this.b2b.isCorporateUser || (this.data.phone && this.isPhoneNumberValid),
          this.areAccepted(get(this, 'info.legalDocuments') || []),
          get(this, 'formStatus.isValid'),
        ], Boolean,
      );
    },
    isDisabled() {
      return this.areSubscriptionsLoading || !this.isFormValid || this.isSubmitButtonDisabled;
    },
    areSubscriptionsEnabled() {
      const isFeatureFlagEnabled = get(this, '$featureFlag.flags.signUpSubscriptions');

      return isFeatureFlagEnabled && !this.ownsEmployeeData;
    },
    mappedTitles() {
      return this.personTitles.map(title => ({ value: title.internalName, label: title.name }));
    },
  },
  watch: {
    promoCode() {
      this.removeAllNotifications();
    },
    promoCodeState: {
      handler(newState) {
        const isLoaded = get(newState, 'STATUS.LOADED');
        const isValidCode = get(newState, 'data.validPromotion');

        if (isLoaded && !isValidCode) {
          this.setForceErrorOnWrongPromoCode(true);
          this.notify({
            type: NOTIFICATION_TYPE.danger,
            text: this.$t('refactor.user_signup.step_4.fields.promo_invalid'),
            textAction: this.$t('refactor.user_signup.step_4.fields.promo_close'),
          });
          this.loading = false;
        } else {
          this.setForceErrorOnWrongPromoCode(false);
        }
      },
      deep: true,
    },
    isPromoCodeCheckLoaded(newStatus) {
      if (newStatus && this.promoCodeStatus) {
        this.registerUser();
      }
    },
    'data.address': {
      handler(newAddress) {
        if (!this.b2b.isCorporateUser && !this.isIdealPostCodeUsed) {
          this.trackAddress(newAddress);
        }
      },
      deep: true,
    },
  },
  beforeCreate() {
    registerModule(this);
  },
  created() {
    this.BUTTON_TYPES = BUTTON_TYPES;
    if (this.areSubscriptionsEnabled) {
      this.setSubscriptionGroups([]);
      this.fetchSubscriptions({ trackingId: null });
    }
  },
  mounted() {
    if (!this.done.step2) {
      this.$router.push({ path: `./${STEPS.two}`, query: this.$route.query });
    }
  },
  methods: {
    fetchSubscriptions,
    setSubscriptionGroups,
    setFirstName,
    setIdNumber,
    setLastName,
    setPromoCode,
    setReceiveNews,
    setTaxNumber,
    setTitle,
    createEmployee,
    createEmployeeFromInvitation,
    createUser,
    setForceErrorOnWrongPromoCode,
    resetInputValidation,
    ...mapActions(PromotionsModuleNameSpace, ['getCheckPromotion']),
    handlerFormSubmit() {
      this.setForceErrorOnWrongPromoCode(false);

      this.trackSegment({
        name: SEGMENT_EVENTS.CREATE_ACCOUNT_CLICK,
      });
      this.trackInteractionEvent({
        eventName: GTM_EVENTS.clickAction,
        eventData: {
          click_text: 'submit-button',
          area: GTM_CLICK_ACTIONS_AREAS.cta,
        },
      });

      if (this.isFormValid && this.checkIdealPostcodes()) {
        this.loading = true;
        if (this.data.promoCode) {
          this.getCheckPromotion({
            code: this.data.promoCode,
            operator: this.data.country.defaultCsOperatorUuid,
          });
        } else {
          this.registerUser();
        }
      }
    },
    async registerUser() {
      try {
        this.loading = true;
        const createAccountMethod = this.isInviteValid ? this.createEmployeeFromInvitation : this.createUser;
        const create = this.isEmployee && !this.isInviteValid ? this.createEmployee : createAccountMethod;

        const subscriptions = this.areSubscriptionsEnabled ? this.subscriptionGroups : null;
        const queryString = get(this, '$route.query', {});

        await create({
          subscriptions,
          queryString,
        });

        if (!this.info.accountCreated) {
          this.loading = false;
          return;
        }

        const isInvitedEmployee = this.isInviteValid && this.isEmployee;

        const eventName = isInvitedEmployee
          ? GTM_EVENTS.b2BSignupAfterInvitation
          : GTM_EVENTS.signUp;

        const trackingPayload = isInvitedEmployee
          ? {
            company_name: this.company.commercialName,
            address: this.info.accountCreated.address,
            email: this.info.accountCreated.email,
            groupId: this.company.uuid,
            company: this.company.uuid,
            tracking_id: this.data.tracking_id,
          }
          : this.info.accountCreated;

        this.trackSegmentIdentify(this.data.tracking_id, trackingPayload);

        let leadType = GTM_SIGN_UP_EVENT_VALUES.B2C;

        if (isInvitedEmployee) {
          leadType = this.b2b.isCorporateUser
            ? GTM_SIGN_UP_EVENT_VALUES.dedicatedB2B
            : GTM_SIGN_UP_EVENT_VALUES.openB2B;
        }

        const eventData = {
          coupon: this.data.promoCode,
          method: 'email',
          lead_type: leadType,
        };
        this.trackInteractionEvent({ eventName, eventData });

        if (shouldSendAttributedEvent(get(this, '$route.query'))) {
          const { source, medium } = UTM_PARAMETERS;

          this.trackSegment({
            name: SEGMENT_EVENTS.ATTRIBUTED_USER_SIGNED_UP,
            data: {
              user_uuid: get(this, 'info.accountCreated.uuid'),
              email: this.data.email,
              country_code: get(this, 'data.country.code'),
              date: moment.utc().format(DATE_FORMAT.filter),
              [source]: get(this, `$route.query.${source}`),
              [medium]: get(this, `$route.query.${medium}`),
            },
          });
        }

        this.isSubmitButtonDisabled = true;
        this.automaticSignIn();
      } catch (error) {
        this.notify({
          delayToClose: false,
          type: NOTIFICATION_TYPE.danger,
          textAction: this.$t('buttons.close'),
          ...errorNotification(error),
        });
      } finally {
        this.loading = false;
      }
    },
    automaticSignIn() {
      const {
        idp_uuid: idpUUID,
        expires_at: expiresAt,
        refresh_token: refreshToken,
        token,
      } = get(this, 'info.accountCreated', {});

      if (idpUUID && expiresAt && refreshToken && token) {
        window.sessionStorage.setItem(SESSION_STORAGE.feedbackModal, SESSION_STORAGE.createAccount);
        this.$router.replace({
          name: 'ImpersonationView',
          query: {
            access_token: token,
            idp_uuid: idpUUID,
            expires_at: expiresAt,
            webapp_impersonate: true,
          },
        });
      } else {
        /* eslint-disable-next-line no-restricted-globals */
        this.$router.push('/', () => location.reload());
      }
    },
    checkIdealPostcodes() {
      const { ukLocation } = this.$refs;
      const validAddress = get(ukLocation, 'address') && get(ukLocation, '$refs.input.value');
      return this.isIdealPostCodeUsed ? validAddress : true;
    },
    onBlurTrackNameCreated() {
      this.trackSegment({
        name: 'Name Created',
        data: {
          name: get(this, 'data.firstName'),
          lastName: get(this, 'data.lastName'),
        },
      });
    },
    trackAddress(field) {
      if (!this.field) {
        return;
      }
      // Field is valid when it contains a empty string
      // I don't know the reason -.-'
      // We will check when the field is valid and also
      // when it's a string with length greater than 0.
      const fieldIsValid = field && field.valid;
      const fieldIsNotEmpty = isString(field.value) && !isEmpty(field.value);

      if (fieldIsValid && fieldIsNotEmpty) {
        // Event is emitted 2 o 3 times but doesn't matter
        // because it's filtered in the marketing App.
        //
        // Normally, it happens when the location is valid
        // but user is doing focus and blur a lot of times.
        //
        // If we need to fix that, we should create a
        // variable control to know when it will be emitted
        // and don't emit more times.
        this.trackSegment({
          name: SEGMENT_EVENTS.ADDRESS_ADDED,
          data: { address: field.value },
        });
      }
    },
    trackAndAcceptLegalDocuments(uuids = []) {
      this.trackSegment({
        name: SEGMENT_EVENTS.TERMS_ACCEPTED,
      });

      this.acceptLegalDocument(uuids);
    },
  },
};
</script>
